import styled, { keyframes } from "styled-components";

const teste = keyframes`
  to {
      transform: rotate(180deg);
    }
`;

export const G4ContainerWait = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 10px;
  font-size: 20px;
  height: calc(465px - 42px);
  font-family: Roboto, sans-serif;

  .NotAtendent {
    margin-top: 10px;
  }

  .iconNotAtendent {
    animation: ${teste} 1.2s infinite;
  }

  .textContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 130px;
    width: 84%;
  }
`;

export const G4WaitImg = styled.div`
  width: 200px;
  height: 170px;
  margin-bottom: 30px;
  background: url(${(props) => props.src});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const LoadWait = styled.span`
  animation: blinker 2s linear infinite;
  font-size: 18px;
`;

export const G4Footer = styled.div`
  height: 42px;
  font-size: 20px;
  font-family: "montserrat";
  /* margin: 10px; */
  color: #fff;
  position: absolute;
  overflow: hidden;
  transition: 0.8s;
  bottom: 0px;
  width: 100%;
  border-radius: 0px 0px 10px 10px;

  ${(props) => props.color && `border: 1px solid ${props.color};`}
  ${(props) => props.color && `background: ${props.color};`}
`;
