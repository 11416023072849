import styled from 'styled-components';

export const Container = styled.div`
  height: 460px;
  width: 100%;
  background: #DCDCDC;

  .g4-content-upload {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DropContainer =  styled.div.attrs({
  className: "dropZone"
})`

  width: 30px;
  text-align: center;
  height: 20px;
  cursor: pointer;

  :focus{
   outline: 0; 
  }
`;

export const PreviewUploadImage = styled.div`
  max-height: ${(props) => props.modeWebchatFull ? '90%' : 'none'};
  max-width: ${(props) => props.modeWebchatFull ? '40%' : 'none'};
  height: ${(props) => props.modeWebchatFull ? '250px' : '130px'};
  width: ${(props) => props.modeWebchatFull ? '250px' : '130px'};
  border-radius: 5px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
`

export const PreviewUploadVideo = styled.div`
  height: 200px;
  width: 300px;
  border-radius: 5px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%; 
`

export const PreviewUploadFile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    font-family: Roboto, sans-serif;
    padding-top: 4px;
    padding-left: 8px;
  }
`

export const HeaderUpload = styled.div`
  color: #fff;
  height: 40px;
  display: flex;
  align-items: center;

  ${(props) => props.color && `background: ${props.color};`}

  h4 {
    margin-left: 20px;
    font-family: Roboto, sans-serif;
    padding-top: 4px;
  }

  svg {
    cursor: pointer;
    margin-left: auto;
    margin-right: 20px;
  }
`;