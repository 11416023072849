import styled from "styled-components";

export const G4ContainerEnd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: calc(465px - 42px);

  p {
    width: 90%;
    margin-top: 20px;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
  }
`;

export const G4EndImgDiv = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;
  vertical-align: middle;
  width: 100%;
  height: 200px;
`;

export const G4EndImg = styled.div`
  width: 200px;
  height: 170px;
  background: url(${props => props.src});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const G4FooterEnd = styled.div`
  height: 42px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  border-radius: 0px 0px 10px 10px;

  ${(props) => props.color && `background: ${props.color};`}
`;
