import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./components/Wait.css";
import { getLogoChatURL } from "../../components/logos";
import { G4ContainerWait, G4WaitImg, G4Footer } from "./components/styled";
import { FaHourglassHalf } from "react-icons/fa";
import Loader from "../../components/loader";

const Wait = () => {
  const [logoURL, setLogoURL] = useState(null);

  const user = useSelector((state) => state.user);
  const config = useSelector((state) => state.config);
  const hours = useSelector((state) => state.hoursOfOut);

  useEffect(() => {
    getLogoChatURL()
      .then((source) => {
        setLogoURL(source);
      })
      .catch(() => console.error("[LOGIN FORM] Error loading chat logo"));
  }, []);

  return (
    <G4ContainerWait>
      <G4WaitImg src={logoURL} />

      {!hours ? (
        <div className="textContent">
          {user.positionQueue === 0 && user.positionQueue === 0 ? (
            <>
              <Loader />
              Transferindo para atendente
            </>
          ) : (
            <>
              <strong>
                {user.positionQueue &&
                  `Olá, 
              ${user.name},`}
              </strong>
              {user.positionQueue &&
                `você está na posição ${user.positionQueue} na fila atendimento`}
            </>
          )}
        </div>
      ) : (
        <>
          <FaHourglassHalf
            size={23}
            color="#24233a"
            className="iconNotAtendent"
          />
          <label className="NotAtendent">Atendimento fora de horário</label>
        </>
      )}

      <G4Footer color={config.color}></G4Footer>
    </G4ContainerWait>
  );
};

export default Wait;
