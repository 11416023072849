import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//import ChatRating from "./components/ChatRating";
import SurveyRating from "./components/SurveyRating";
import { nextPage } from "../../store/page/actions";
import { Container } from "./style";

const END = "End";

const CustumerExperienceSurvey = () => {
  const config = useSelector((state) => state.config);
  const survey = useSelector((state) => state.survey);
  const [surveyConfig, setSurveyConfig] = useState();
  const dispatch = useDispatch();

  if (survey.active === false) {
    dispatch(nextPage(END));
  }

  useEffect(() => {
    setSurveyConfig({
      survey: { ...survey.survey },
      conversationId: survey.conversationId,
      active: survey.active,
    });
  }, [survey, dispatch]);

  return (
    <Container>
      {surveyConfig && surveyConfig.active === true && (
        <>
          <SurveyRating
            conversationId={surveyConfig.conversationId}
            surveyConfig={surveyConfig.survey}
            color={config.color}
          />
        </>
      )}
    </Container>
  );
};

export default CustumerExperienceSurvey;
