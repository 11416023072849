import React from "react";
import { useSelector } from 'react-redux';
import { Spinner } from './style';

const Loader = () => {
  const config = useSelector((state) => state.config);
  return <Spinner picker={config.color} />;
};

export default Loader;
