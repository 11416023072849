import styled from "styled-components";

export const G4Header = styled.header`
  width: 100%;
  height: ${(props) => props.modeWebchatFull ? '60px' : '42px'};

  @media screen and (min-width: 1440px) {
    margin-bottom: ${(props) => props.modeWebchatFull ? '50px' : 'none'};

  }

  border-radius: 10px 10px 0 0;
  display: flex;
  color: white;
  align-items: center;
  background: ${props => (props.modeWebchatFull ? props.modeWebchatFull : props.color)};

  .minimize {
    margin-left: auto;
    margin-right: 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  svg {
    cursor: pointer;
  }
`;

export const G4Avatar = styled.img`
  height: ${(props) => props.modeWebchatFull ? '60px' : '50px'};
  margin: ${(props) => props.modeWebchatFull ? '0px 0px 0px 25px' : '-30px 0px 0px 25px'};
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.21);
  background: white;
`;

export const G4HeaderItens = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.modeWebchatFull ? '24px' : '17px'};
  color: ${(props) => props.modeWebchatFull ? '#32325d' : 'none'};

  .circleOnline {
    height: 12px;
    width: 12px;
    background-color: ${props => props.onlineAtendent ? '#c05353' : '#34fe60'};
    border-radius: 50%;
    margin: ${(props) => props.modeWebchatFull ? '0 10px -35px -12px' : '0 10px 0 -12px'};
  }
`;
