import React from "react";
import { FaFilePdf } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Upload } from "./style";
import {
  G4Cliente,
  G4Wait,
  G4Atendente,
} from "../../../pages/conversation/components/styled";

import { Hours } from "../../styled";

const MessageFile = ({ file, user, time }) => {
  const nameFile = useSelector((state) => state.getNameFileReduce);
  const config = useSelector((state) => state.config);
  const content = (
    <>
      <Upload>
        <a href={file} target="blank">
          <FaFilePdf size={25} color="#f40f02" />
          <p style={{ color: user === "atendente" ? "#2f4f4f" : "#000" }}>
            Clique aqui
          </p>
        </a>
      </Upload>
      <Hours>
        {new Date(time).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })}
      </Hours>
    </>
  );

  return (
    <>
      {user === "cliente" && (
        <G4Cliente color={config.color}>{content}</G4Cliente>
      )}
      {user === "atendente" && <G4Atendente>{content}</G4Atendente>}
      {user === "wait" && <G4Wait color={config.color}>{content}</G4Wait>}
    </>
  );
};

export default MessageFile;
