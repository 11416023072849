import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { isMobile, browserName } from "react-device-detect";
import axios from "axios";
import { apiHttp, apiIntegration } from "../../services/api-http";
import Dropzone from "react-dropzone";
import { FaPaperclip } from "react-icons/fa";
import { MdSend } from "react-icons/md";
import Upload from "./components/upload/index";
import AudioSender from "./components/AudioSender/index";
import Message from "../../components/message/Message";
import MessageImage from "../../components/message/message-image/MessgeImage";
import MessageFile from "../../components/message/message-file/index";
import MessageLink from "../../components/message/message-link/index";
import MessageOption from "../../components/message/message-option/MessageOption";
import MessageAudio from "../../components/message/message-audio/index";
import MessageVideo from "../../components/message/message-video/MessageVideo";
import {
  DropContainer,
  G4ContainerMessages,
  G4ContentInput,
  G4InputMessage,
  G4Button,
  ContentMessages,
  ProfileBallon,
  Arrow,
  ContainerBody,
} from "./components/styled";
import "./components/Conversation.css";
import apiSocket from "../../services/api-socket";
import { addMessages, addSessionId } from "../../store/conversation/actions";
import { addIdConversation } from "../../store/user/actions";
import { getLogoFlexiaURL } from "../../components/logos/index";
import Loader from "../../components/loader";
import { useContext } from "react";
import { WebchatContext } from "../../context/WebchatContext";
import { io } from "socket.io-client";

const Conversation = ({ size }) => {
  const messagesEndRef = useRef();
  const [logoFlexiaURL, setLogoFlexiaURL] = useState(null);
  const SOCKET_IO_URL = `${process.env.REACT_APP_PROXY_URL}`;

  const socket = useMemo(() => {
    return io(SOCKET_IO_URL, {
      path: "/channel",
    });
  }, [SOCKET_IO_URL]);

  useEffect(() => {
    socket.on("connect", () => {});
  }, [socket]);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const conversation = useSelector((state) => state.conversation);
  const config = useSelector((state) => state.config);

  const { modeWebchatFull } = useContext(WebchatContext);

  const [clientTyping, setClientTyping] = useState(false);
  const [attendantTyping, setAttendantTyping] = useState(false);
  const [valueInput, setValueInput] = useState("");
  const [recording, setRecording] = useState(false);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      message: "",
    },
  });
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    getLogoFlexiaURL()
      .then((source) => {
        setLogoFlexiaURL(source);
      })
      .catch(() => console.error("[LOGIN FORM] Error loading flexia logo"));
  }, []);

  useEffect(() => {
    let mobile;
    let origem;
    let platform = navigator.platform;

    if (isMobile) {
      origem = browserName;
      mobile = true;
    } else {
      origem = browserName;
      mobile = false;
    }

    if (config.useBot && !conversation.conversationAttendant) {
      apiIntegration
        .post("/webchat/init-chat", {
          name: user.name,
          email: user.email,
          phone: user.telefone,
          formulario: true,
          navegador: origem,
          plataforma: platform,
          isMobile: mobile,
          socketId: apiSocket.id,
          message: "OlÃ¡ {Site} <Comercial>",
        })
        .then((response) => {
          dispatch(addMessages(response.data.data.mensagens));
          dispatch(addIdConversation(response.data.data.id));
          dispatch(addSessionId(response.data.data.idSessao));
          localStorage.setItem(
            "IdConversation",
            response.data.data._id ?? response.data.data.id
          );
          localStorage.setItem(
            "conversationAttendant",
            conversation.conversationAttendant
          );
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  }, [
    config.useBot,
    conversation.conversationAttendant,
    dispatch,
    user.email,
    user.name,
    user.telefone,
  ]);

  useEffect(() => {
    scrollToBottom();
  }, [conversation]);

  //inicio conversar com atendente
  function sendMessageAttendant(message) {
    const mensagem = {
      texto: message,
      response_type: "text",
      cliente_ou_atendente: "cliente",
      hora_da_mensagem: new Date().getTime(),
      escrita_por: user.name,
    };

    apiIntegration
      .post("/webchat/message/text", {
        chatId: user.idConversa,
        message: message,
      })
      .catch((err) => {
        console.log("err: ", err);
      });

    // apiSocket.emit("cliente_enviar_mensagem", {
    //   idDaConversa: user.idConversa,
    //   mensagem,
    // });

    dispatch(addMessages([mensagem]));

    reset();
  }
  //fim conversar com atendente

  //inicio conversar com bot
  function sendMessageBot(message) {
    dispatch(
      addMessages([
        {
          cliente_ou_atendente: "cliente",
          texto: message,
          hora_da_mensagem: new Date().getTime(),
        },
      ])
    );

    apiIntegration
      .post("/webchat/message/text", {
        chatId: user.idConversa,
        message: message,
      })
      .catch((err) => {
        console.log("err: ", err);
      });

    reset();
  }
  //fim conversar com bot

  const [previewImages, setPreviewImages] = useState([]);
  const [stateUpload, setStateUpload] = useState([]);
  const [stateUploadName, setStateUploadName] = useState({});

  useEffect(() => {
    scrollToBottom();
  }, [previewImages]);

  const loadUpload = (files) => {
    setStateUpload(files[0]);
    setStateUploadName(files[0].name);

    if (!files) {
      return;
    }

    const selectImages = Array.from(files);

    const selectedImagesPreview = selectImages.map((image) => {
      return URL.createObjectURL(image);
    });
    setPreviewImages(selectedImagesPreview);
    setValueInput("valor");
  };

  function sendUpload() {
    const data = new FormData();
    data.append("file", stateUpload);
    data.append("chatId", user.idConversa);
    data.append("cliente_ou_atendente", "cliente");
    data.append("escrita_por", user.name);
    data.append("legenda", "");

    if (
      stateUpload.type === "image/jpeg" ||
      stateUpload.type === "image/jpg" ||
      stateUpload.type === "image/png"
    ) {
      data.append("type", "image");
    } else if (stateUpload.type === "application/pdf") {
      data.append("type", "file");
    } else if (stateUpload.type === "video/mp4") {
      data.append("type", "video");
    }

    apiIntegration
      .post("/webchat/message/midia", data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        const mensagem = {
          ...response.data.data,
          hora_da_mensagem: new Date().getTime(),
        };
        dispatch(addMessages(mensagem));

        setValueInput("");
      })
      .catch((error) => {
        console.log("teve erro", error);
      });

    setStateUpload([]);
    setPreviewImages([]);
  }

  useEffect(() => {
    apiSocket.on("digitando_atendente", (data) => {
      setClientTyping(data.digitando);
    });
    apiSocket.on("parou_digitar_atendente", (data) => {
      setClientTyping(data.digitando);
    });
  }, []);

  function sendMessage({ message }) {
    if (config.useBot && !conversation.conversationAttendant) {
      sendMessageBot(message);
      setValueInput("");
    } else {
      sendMessageAttendant(message);
      setValueInput("");
      socket.emit("parou_digitar", user.idConversa);
      setAttendantTyping(false);
    }
  }

  function cleanUplaod() {
    setStateUpload([]);
    setPreviewImages([]);
  }

  const handleMessageChange = (event) => {
    const text = event.target.value;

    setValueInput(text);
    if (text.length !== 0 && attendantTyping === false) {
      setAttendantTyping(true);
      socket.emit("digitando", user.idConversa);
    } else if (text.length === 0) {
      setAttendantTyping(false);
      socket.emit("parou_digitar", user.idConversa);
    }
  };

  return (
    <ContainerBody modeWebchatFull={modeWebchatFull}>
      <G4ContainerMessages modeWebchatFull={modeWebchatFull}>
        {conversation.messages.length === 0 ? (
          <div className="loaderContent">
            <Loader />
          </div>
        ) : (
          conversation.messages.map((message, index) => {
            if (message.response_type === "image") {
              return (
                <ContentMessages>
                  {message.cliente_ou_atendente === "atendente" && (
                    <>
                      <ProfileBallon src={logoFlexiaURL} />
                      <Arrow />
                    </>
                  )}
                  <MessageImage
                    size={size}
                    key={index}
                    source={message.source}
                    time={message.hora_da_mensagem || new Date()}
                    user={message.cliente_ou_atendente}
                  />
                </ContentMessages>
              );
            } else if (message.response_type === "file") {
              return (
                <ContentMessages>
                  {message.cliente_ou_atendente === "atendente" && (
                    <>
                      <ProfileBallon src={logoFlexiaURL} />
                      <Arrow />
                    </>
                  )}
                  <MessageFile
                    size={size}
                    time={message.hora_da_mensagem}
                    user={message.cliente_ou_atendente}
                    file={message.source}
                  />
                </ContentMessages>
              );
            } else if (message.response_type === "option") {
              return (
                <ContentMessages>
                  {message.cliente_ou_atendente === "atendente" && (
                    <div style={{ width: "44px", background: "transparent" }} />
                  )}
                  {message.cliente_ou_atendente === "atendente" && (
                    <Arrow option />
                  )}
                  <MessageOption
                    size={size}
                    key={index}
                    time={message.hora_da_mensagem}
                    options={message.options}
                    user={message.cliente_ou_atendente}
                  />
                </ContentMessages>
              );
            } else if (message.response_type === "link") {
              return (
                <ContentMessages>
                  {message.cliente_ou_atendente === "atendente" && (
                    <ProfileBallon src={logoFlexiaURL} />
                  )}
                  {message.cliente_ou_atendente === "atendente" && <Arrow />}
                  <MessageLink
                    size={size}
                    key={index}
                    time={message.hora_da_mensagem}
                    text={message.texto ? message.texto : message.title}
                    user={message.cliente_ou_atendente}
                  />
                </ContentMessages>
              );
            } else if (message.response_type === "audio") {
              return (
                <ContentMessages>
                  {message.cliente_ou_atendente === "atendente" && (
                    <ProfileBallon src={logoFlexiaURL} />
                  )}
                  {message.cliente_ou_atendente === "atendente" && <Arrow />}
                  <MessageAudio
                    size={size}
                    key={index}
                    time={message.hora_da_mensagem}
                    source={message.source}
                    text={message.texto ? message.texto : message.title}
                    user={message.cliente_ou_atendente}
                  />
                </ContentMessages>
              );
            } else if (message.response_type === "video") {
              return (
                <ContentMessages>
                  {message.cliente_ou_atendente === "atendente" && (
                    <>
                      <ProfileBallon src={logoFlexiaURL} />
                      <Arrow />
                    </>
                  )}
                  <MessageVideo
                    size={size}
                    key={index}
                    source={message.source}
                    time={message.hora_da_mensagem || new Date()}
                    user={message.cliente_ou_atendente}
                  />
                </ContentMessages>
              );
            } else {
              return (
                <ContentMessages>
                  {message.cliente_ou_atendente === "atendente" && (
                    <ProfileBallon src={logoFlexiaURL} />
                  )}
                  {message.cliente_ou_atendente === "atendente" && <Arrow />}
                  <Message
                    key={index}
                    size={size}
                    text={message.texto ? message.texto : message.title}
                    time={message.hora_da_mensagem}
                    user={message.cliente_ou_atendente}
                  />
                </ContentMessages>
              );
            }
          })
        )}

        <Upload
          cleanUplaod={cleanUplaod}
          file={previewImages}
          stateFile={stateUpload}
          stateUploadName={stateUploadName}
        />

        <div ref={messagesEndRef} />
      </G4ContainerMessages>

      {/* <G4TextRealTime>
        {/* {clientTyping && (
          <>
            o atendente estÃ¡ digitando
            <G4Typing>...</G4Typing>
          </>
        )} */}
      {/* </ContainerBody>/</G4TextRealTime> */}

      <form
        autoComplete="off"
        onSubmit={handleSubmit(
          stateUpload.length !== 0 ? sendUpload : sendMessage
        )}
      >
        <G4ContentInput modeWebchatFull={modeWebchatFull}>
          {stateUpload.length === 0 && (
            <Dropzone
              onDropAccepted={loadUpload}
              accept=".pdf, image/*, video/*"
              className="dropZone"
            >
              {({ getRootProps, getInputProps }) => (
                <DropContainer {...getRootProps()}>
                  <FaPaperclip size={20} color="#2f4f4f" />
                  <input autoComplete="newPassword" {...getInputProps()} />
                </DropContainer>
              )}
            </Dropzone>
          )}
          {stateUpload.length === 0 ? (
            <G4InputMessage
              isRecordingAudio={setRecording}
              name="message"
              type="text"
              ref={register}
              placeholder="Digite sua mensagem"
              onChange={handleMessageChange}
            />
          ) : (
            <G4InputMessage
              disabled
              type="text"
              placeholder="Digite sua mensagem"
            />
          )}
          {valueInput !== "" ? (
            <G4Button type="submit" color="#fff">
              <MdSend size="90%" color={config.color} />
            </G4Button>
          ) : (
            <>
              {window.isSecureContext === true && (
                <AudioSender id={user.idConversa} setRecording={setRecording} />
              )}

              <G4Button cursor="default" type="submit" color="#fff" disabled>
                <MdSend size="90%" color="#ccc" />
              </G4Button>
            </>
          )}
        </G4ContentInput>
      </form>
    </ContainerBody>
  );
};

export default Conversation;
