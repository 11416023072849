import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNameFile } from "../../../../store/action/getNameFile";
import { MdClose } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa";
import {
  Container,
  HeaderUpload,
  PreviewUploadImage,
  PreviewUploadFile,
  PreviewUploadVideo,
} from "./style";
import { useContext } from "react";
import { WebchatContext } from "../../../../context/WebchatContext";

const Upload = forwardRef(
  ({ file, stateFile, cleanUplaod, stateUploadName }) => {
    const dispatch = useDispatch();
    const [preview, setPreview] = useState([]);
    const messagesEndRef = useRef();
    const config = useSelector((state) => state.config);

    const modeWebchatFull = useContext(WebchatContext);


    const scrollToBottom = () => {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
      dispatch(getNameFile(stateUploadName));
    }, [stateUploadName, dispatch]);

    useEffect(() => {
      setPreview(file);
    }, [file]);

    useEffect(() => {
      if (preview.length !== 0) {
        scrollToBottom();
      }
    }, [preview]);

    function clearPreviewUpload() {
      setPreview([]);
      cleanUplaod();
    }
    return (
      <>
        {preview.map((image, index) => {
          return (
            <Container key={index} ref={messagesEndRef}>
              <HeaderUpload color={config.color}>
                <h4>Pré-vizualização</h4>
                <MdClose
                  onClick={clearPreviewUpload}
                  size={20}
                  type="button"
                  color="white"
                />
              </HeaderUpload>
              <div className="g4-content-upload">
                {(stateFile.type === "image/jpeg" ||
                  stateFile.type === "image/jpg" ||
                  stateFile.type === "image/png") && (
                  <PreviewUploadImage modeWebchatFull={modeWebchatFull} src={image}/>
                )}
                {stateFile.type === "application/pdf" && (
                  <PreviewUploadFile>
                    <FaFilePdf size={30} color={"#f40f02"} />
                    <h4>{stateFile.name}</h4>
                  </PreviewUploadFile>
                )}
                {stateFile.type === "video/mp4" && (
                  <PreviewUploadVideo>
                    {/* <h4>{stateFile.name}</h4> */}
                    <video width="300" height="200" controls>
                      <source src={image} type="video/mp4" />
                    </video>
                  </PreviewUploadVideo>
                )}
              </div>
            </Container>
          );
        })}
      </>
    );
  }
);

export default Upload;
