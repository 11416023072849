import styled from 'styled-components';

export const ContentOption = styled.div`

    .g4-message-option-link {
        color: #395c76;
        text-decoration: none;
        font-family: Roboto;
        font-size: 13px;
    }
`;