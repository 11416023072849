import { apiHttpNotOpen } from "../../services/api-http";

const getExternalSource = (logoURL) => {
  const baseURL = process.env.REACT_APP_CONFIGURATION_URL;

  return `${baseURL}${logoURL}`;
};

export const getLogoChatURL = async () => {
  const data = await apiHttpNotOpen.get("api/open/configurations/webchat");

  const externalSource = data?.data?.data?.logoChat;

  return new Promise((resolve) => {
    const image = new Image();

    image.onload = () => {
      resolve(image.src);
    };

    image.src = externalSource;
  });
};

export const getLogoFlexiaURL = async () => {
  const data = await apiHttpNotOpen.get("api/open/configurations/webchat");

  const externalSource = data?.data?.data?.profilePic;

  return new Promise((resolve) => {
    const image = new Image();

    image.onload = () => {
      resolve(image.src);
    };

    image.src = externalSource;
  });
};
