import React from "react";
import { MessageImageStyle } from "./style";
import { useSelector } from "react-redux";
import {
  G4Cliente,
  G4Wait,
  G4Atendente,
} from "../../../pages/conversation/components/styled";
import { Hours } from "../../styled";
import { WebchatContext } from "../../../context/WebchatContext";
import { useContext } from "react";


const Message = ({ time, source, user }) => {
  const config = useSelector((state) => state.config);
  const {modeWebchatFull} = useContext(WebchatContext);
  const content = (
    <>
      <a href={source} target="blank">
        <MessageImageStyle modeWebchatFull={modeWebchatFull} src={source} />
      </a>
      <Hours>
      {new Date(time).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })}
      </Hours>
    </>
  );

  return (
    <>
      {user === "cliente" && (
        <G4Cliente modeWebchatFull={modeWebchatFull} color={config.color}>{content}</G4Cliente>
      )}
      {user === "atendente" && <G4Atendente modeWebchatFull={modeWebchatFull}>{content}</G4Atendente>}
      {user === "wait" && <G4Wait color={config.color}>{content}</G4Wait>}
    </>
  );
};

export default Message;
